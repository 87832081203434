import React, {useEffect} from 'react'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import * as styleSheet from "./index.module.scss"


const CheckoutForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();


  useEffect(() => {
	if( props.formValidates ){				
		// Use elements.getElement to get a reference to the mounted Element.
		const cardElement = elements.getElement(CardElement);

		// Pass the Element directly to other Stripe.js methods:
		// e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
		// let {token} = stripe.createToken(cardElement)
		stripe.createToken(cardElement).then(function(result) {
			// Handle result.error or result.token
			if( result.token ){
				props.submitForm(result.token);
			} else {
				props.setProcessing(false);
			}
		});

	} else {
		props.setProcessing(false);
	}


  }, [props.formValidates]);


function handleSubmit(event) {
	if(event) {
		event.preventDefault();
	}

	if( !props.isProcessing){
		props.setProcessing(true);
		props.validate();
	}
}

function handleKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
		handleSubmit();
	}
  }

  return (
    <>
      <CardElement />
      <button onClick={handleSubmit} onKeyDown={(e) => handleKeyDown(e)} className={styleSheet.paymentformsubmit}>Pay</button>
    </>

	
  );
};

export default CheckoutForm