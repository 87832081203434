import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import PayMyBillForm from "../components/payMyBillForm";

import Seo from 'gatsby-plugin-wpgraphql-seo';

import * as styleSheet from "./payMyBill.module.scss"



const PayMyBill = (props) => {
    // Migration guide to new React Stripe lib
    // https://github.com/stripe/react-stripe-js/blob/master/docs/migrating.md

	const {
		data: {
			pageInfo,
			pageInfo: {
				title,
				ACFPayMyBill: {
          payMyBill: {
              payLeft: {
                blurb,
                insurancesHeading,
              },
              payRight: {
                introContent: {
                  blurb: introBlurb,
                  heading: introHeading,
                },
                textContent,
              }
            }
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
              mobileNavigationLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
		}
	} = props;

	return (
		<Layout>
			<Seo post={pageInfo} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						{ blurb && <div className={styleSheet.patientheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.patientcontent}>
						<div className={styleSheet.contentleft}>
							{ textContent && <div className="wpcontent" dangerouslySetInnerHTML={{__html: textContent}}></div> }
						</div>
                  
                  
            <PayMyBillForm />


					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  pageInfo: wpPage(id: {eq: $id}) {
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    id
    title
    ACFPayMyBill {
      payMyBill {
        payLeft {
          blurb
          insurancesHeading
        }
        payRight {
          introContent {
            blurb
            heading
          }
          textContent
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  insurance: allWpInsurance {
    nodes {
      ACFInsurance {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 420, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
      }
    }
  }
}
`;

export default PayMyBill;