import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

import CheckoutForm from '../checkoutForm'

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import * as styleSheet from "./index.module.scss"
import "react-datepicker/dist/react-datepicker.css";

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLIC_KEY}`);

export default function PayMyBillForm() {

	const data = useStaticQuery(graphql`
	query payQuery {
		locations: allWpLocation(sort: {fields: title}) {
			nodes {
				title
			}
		}
	}
`);

	useEffect(() => {
		setWindowExists(true);
	},[]);

	const { locations } = data;

	// const fullWidthCalloutProps = {
	// 	headline: paymybill.acf.appointment_cta.smiles_headline ? paymybill.acf.appointment_cta.smiles_headline : "Your smile made easy.",
	// 	button: paymybill.acf.appointment_cta.smiles_button ? paymybill.acf.appointment_cta.smiles_button : "Schedule your free consultation!"
	// };

	const [formresponse, setFormresponse] = useState( '' );
	const [showform, setShowform] = useState( true );
	const [processing, setProcessing] = useState( false );
	const [formErrors, setFormErrors] = useState( false );
	const [paymentError, setPaymentError] = useState( false );

	const [windowExists, setWindowExists] = useState(false);

	const [patientLocation, setPatientLocation] = useState({
		value: '',
		errors: null
	});

	const [patientName, setPatientName] = useState({
		value: '',
		errors: null
	});

	const [patientDOB, setPatientDOB] = useState({
		value: '',
		errors: null
	});

	const [responsibleName, setResponsibleName] = useState({
		value: '',
		errors: null
	});

	const [responsiblePhone, setResponsiblePhone] = useState({
		value: '',
		errors: null
	});

	const [responsibleEmail, setResponsibleEmail] = useState({
		value: '',
		errors: null
	});

	const [responsibleEmailConfirm, setResponsibleEmailConfirm] = useState({
		value: '',
		errors: null
	});

	const [centAmount, setCentAmount] = useState({
		value: "0",
		errors: null
	});

	const [dollarAmount, setDollarAmount] = useState({
		value: "0",
		errors: null
	});

	const [formValidates, setFormValidates] = useState(false);

	function isValidEmailAddress(emailAddress) {
		var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
		return pattern.test(emailAddress);
	};

	const validate = () => {
		setFormValidates(false);

		let errors = false;
		setFormErrors(false);

		if( patientLocation.value === ''){
			setPatientLocation(prev => ({ 
				...prev,
				errors: ["This field is required."]
			}));
			errors = true;
		} else {
			setPatientLocation(prev => ({ 
				...prev,
				errors: null
			}));
		}
		
		if( patientName.value === ''){
			setPatientName(prev => ({ 
				...prev,
				errors: ["This field is required."]
			}));
			errors = true;
		} else {
			setPatientName(prev => ({ 
				...prev,
				errors: null
			}));
		}

		if( patientDOB.value === ''){
			setPatientDOB(prev => ({ 
				...prev,
				errors: ["This field is required."]
			}));
			errors = true;
		} else {
			setPatientDOB(prev => ({ 
				...prev,
				errors: null
			}));
		}

		if( responsibleName.value === ''){
			setResponsibleName(prev => ({ 
				...prev,
				errors: ["This field is required."]
			}));
			errors = true;
		} else {
			setResponsibleName(prev => ({ 
				...prev,
				errors: null
			}));
		}

		if( responsiblePhone.value === ''){
			setResponsiblePhone(prev => ({ 
				...prev,
				errors: ["This field is required."]
			}));
			errors = true;
		} else {
			setResponsiblePhone(prev => ({ 
				...prev,
				errors: null
			}));
		}

		if( responsibleEmail.value === ''){
			setResponsibleEmail(prev => ({ 
				...prev,
				errors: ["This field is required."]
			}));
			errors = true;
		} else {
			if( isValidEmailAddress( responsibleEmail.value ) ){
				setResponsibleEmail(prev => ({ 
					...prev,
					errors: null
				}));
			} else {
				setResponsibleEmail(prev => ({ 
					...prev,
					errors: ["This doesn't appear to be a valid email address."]
				}));
				errors = true;
			}
		}

		if( responsibleEmailConfirm.value !== responsibleEmail.value){
			setResponsibleEmailConfirm(prev => ({ 
				...prev,
				errors: ["This field doesn't match your email address."]
			}));
			errors = true;
		} else {
			setResponsibleEmailConfirm(prev => ({ 
				...prev,
				errors: null
			}));
		}

		if( (centAmount.value === 0) || (centAmount.value === '') || (centAmount.value === "0") ){
			setCentAmount(prev => ({ 
				...prev,
				errors: ["This field is required"]
			}));
			errors = true;
		} else {
			setCentAmount(prev => ({ 
				...prev,
				errors: null
			}));
		}

		if( errors ){
			setFormValidates(false);
			setFormErrors(true);
			setProcessing(false);
		} else {
			setFormValidates(true);
		}
	};

	function xwwwfurlenc(srcjson){
		if(typeof srcjson !== "object"){
			if(typeof console !== "undefined"){
				return null;
			}
		}
		var u = encodeURIComponent;
		var urljson = "";
		var keys = Object.keys(srcjson);
		for(var i=0; i <keys.length; i++){
			urljson += u(keys[i]) + "=" + u(srcjson[keys[i]]);
			if(i < (keys.length-1))urljson+="&";
		}
		return urljson;
	}


	const submitForm = async (token) => {

		setPaymentError(false);
		const url = "" + process.env.GATSBY_WP_BASE_URL + "/wp-json/perch_endpoint/v1/check_handle?token="+token.id;
		
		const data = {
			token: token.id,
			patientLocation: patientLocation.value,
			patientName: patientName.value,
			patientDOB: patientDOB.value,
			responsibleName: responsibleName.value,
			responsiblePhone: responsiblePhone.value,
			responsibleEmail: responsibleEmail.value,
			responsibleEmailConfirm: responsibleEmailConfirm.value,
			centAmount: centAmount.value
		};

	
		try {
			await fetch(url, { 
				method: 'post', 
				headers: new Headers({
					'Content-Type': 'application/x-www-form-urlencoded'
				}), 
				body: xwwwfurlenc(data)
			}).then(async function(response){
				const message = await response.json();

				message && message.status && message.status === "succeeded" && setFormresponse("<h2>Your payment was successful, thank you.</h2><p>You will be receiving an email confirmation of your payment at <strong>"+responsibleEmail.value+"</strong>.</p>");
				message && message.status && message.status === "succeeded" && setShowform(false);
				message && !message.status && setPaymentError(true);
				setProcessing(false);
			});
		} catch(err) {
			console.log(err)
			setPaymentError(true);
			
		}
	};

	let allLocations = locations.nodes.map( (location, l) => {
		return location.title;
	});


    return (
		<>
		{showform && (
		<form className={styleSheet.paymentform} onSubmit={validate}>
			<fieldset>
				<legend>Patient Info</legend>
		
				<label className={styleSheet.label}>Treatment Location
					<div className={styleSheet.selectContainer}>
						<select 
							name="treatment_location" 
							value={patientLocation.value}
							className={styleSheet.select} 
							aria-describedby="error-treatmentlocation-required"
							onChange={name => {
								let newName = name.target.value;
								setPatientLocation(prev => ({
									...prev,
									value: newName,
								}))
							}}>
								<option value="">Select a Treatment Location</option>
							{ allLocations.map( (location, l) => {
								return <option key={"loc-" + l} value={location} dangerouslySetInnerHTML={{ __html: location}}></option>
							})}
						</select>
					</div>
					{ patientLocation.errors && patientLocation.errors.map((error, i) => { return <span role="alert" id="error-treatmentlocation-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>
							
				<label className={styleSheet.label}>Patient's Name
					<input 
						className={styleSheet.inputText} 
						name="patient_name" 
						type="text" 
						placeholder="Patient's Name" 
						aria-describedby="error-patientsname-required"
						value={patientName.value}
						onChange={name => {
							let newName = name.target.value;
							setPatientName(prev => ({ 
								...prev,
								value: newName,
							}))
						}} />
						{ patientName.errors && patientName.errors.map((error, i) => { return <span role="alert" id="error-patientsname-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>

				<label className={styleSheet.label}>Patient's DOB
					<DatePicker
						selected={patientDOB.value}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						onChange={date => {
							setPatientDOB(prev => ({ 
								...prev,
								value: date,
							}));
						}}
						name="patient_dob"
						aria-describedby="error-patientsdob-required"
						/>
					{ patientDOB.errors && patientDOB.errors.map((error, i) => { return <span role="alert" id="error-patientsdob-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>
			</fieldset>

			<fieldset>
				<legend>Responsible Party</legend>

				<label className={styleSheet.label}>Responsible Party's Name
					<input 
						className={styleSheet.inputText} 
						name="responsible_name" 
						type="text" 
						placeholder="Responsible Party's Name" 
						aria-describedby="error-responsiblename-required"
						value={responsibleName.value}
						onChange={name => {
							let newName = name.target.value;
							setResponsibleName(prev => ({ 
								...prev,
								value: newName
							}))
						}} />
					{ responsibleName.errors && responsibleName.errors.map((error, i) => { return <span role="alert" id="error-responsiblename-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>

				<label className={styleSheet.label}>Responsible Party's Phone Number
					<input 
						className={styleSheet.inputPhone} 
						name="responsible_phone" 
						type="tel" 
						placeholder="Responsible Party's Phone" 
						aria-describedby="error-responsiblephone-required"
						value={responsiblePhone.value}
						onChange={name => {
							let newName = name.target.value;
							setResponsiblePhone(prev => ({ 
								...prev,
								value: newName,
							}))
						}} />
					{ responsiblePhone.errors && responsiblePhone.errors.map((error, i) => { return <span role="alert" id="error-responsiblephone-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>

				<label className={styleSheet.label}>Responsible Party's Email Address
					<input 
						className={styleSheet.inputEmail} 
						name="responsible_email" 
						type="email" 
						placeholder="Responsible Party's Email" 
						aria-describedby="error-responsibleemail-required"
						value={responsibleEmail.value}
						onChange={name => {
							let newName = name.target.value;
							setResponsibleEmail(prev => ({ 
								...prev,
								value: newName,
							}))
						}} />
					{ responsibleEmail.errors && responsibleEmail.errors.map((error, i) => { return <span role="alert" id="error-responsibleemail-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>

				<label className={styleSheet.label}>Responsible Party's Email Address Confirmation
					<input 
						className={styleSheet.inputEmail} 
						name="responsible_email_confirm" 
						type="email" 
						placeholder="Responsible Party's Email" 
						aria-describedby="error-responsibleemailconfirm-required"
						value={responsibleEmailConfirm.value}
						onChange={name => {
							let newName = name.target.value;
							setResponsibleEmailConfirm(prev => ({ 
								...prev,
								value: newName,
							}))
						}} />
					{ responsibleEmailConfirm.errors && responsibleEmailConfirm.errors.map((error, i) => { return <span role="alert" id="error-responsibleemailconfirm-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>
			</fieldset>

			<fieldset>
				<legend>Payment</legend>

				<label className={styleSheet.label}>Amount

					<CurrencyFormat className={styleSheet.inputText} value={dollarAmount.value} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} allowNegative={false}  onValueChange={(values) => {
						const {formattedValue, value} = values;
						// formattedValue = $2,223
						let newVal = value.toString().replace(".", "");
						// value ie, 2223
						setCentAmount(prev => ({ 
							...prev,
							value: newVal
						}));
			
						setDollarAmount(prev => ({ 
							...prev,
							value: formattedValue
						}));
					}}/>
					{ centAmount.errors && centAmount.errors.map((error, i) => { return <span role="alert" id="error-centsamount-required" className={styleSheet.alert} key={i}>{error}</span>; }) }
				</label>

				{ windowExists && <Elements stripe={stripePromise}>
						<CheckoutForm formValidates={formValidates} validate={validate} submitForm={submitForm} processing={processing} setProcessing={setProcessing} />
					</Elements>}
				{ paymentError && <span role="alert" className={styleSheet.alert}>Sorry, there was an error and the transaction could not be completed. Please try again later.</span>}
			</fieldset>
		</form>)}

		{ formresponse !== "" ? <div className={styleSheet.formresponse} dangerouslySetInnerHTML={{ __html: formresponse }}></div> : null }

		{formErrors && <span className={styleSheet.errors}>Oops! Please resolve the errors above.</span>}
	</>
    );
}


